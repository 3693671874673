
import { defineComponent, ref, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import CryptoJS from "crypto-js";
import { useStore } from "vuex";
import AuthService from "@/services/AuthService";

export default defineComponent({
  name: "login",

  components: {
    Field,
    Form,
    ErrorMessage,
  },

  setup() {
console.log('NODE_ENV =', process.env.NODE_ENV);
    const distribuidor = "";
    const router = useRouter();
    const store = useStore();
    const linkIOS = ref("https://apps.apple.com/br/app/indicar/id1573980796");
    const linkAndroid = ref(
      "https://play.google.com/store/apps/details?id=br.com.camptecnologia.indicar&hl=pt_BR&gl=US&pli=1"
    );
    const fullscreenLoading = ref(false);

    const remember = ref(true);

    // remember",remember);
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email("Email deve ser valido")
        .required("Informe email")
        .label("Email"),
      password: Yup.string()
        .min(4, "Deve ser maior que ${min}")
        .required("Informe senha")
        .label("Senha"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // values.email = CryptoJS.MD5(values.email.toLowerCase()).toString();
      // values.senha = CryptoJS.MD5(values.senha.toLowerCase()).toString();

      values.rememberme = remember.value ? 1 : 0;

      values.email = values.email.toLowerCase().toString();
      values.password = values.password.toString();

      // Clear existing errors
      // console.log('Clear existing errors');
      store.dispatch(Actions.CLEAR);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        submitButton.value.setAttribute("disabled", "disabled");
      }

      // Send login request
      store
        .dispatch(Actions.AUTENTICACAO, values)
        .then((response) => {
          fullscreenLoading.value = false;
          // console.log('login sucesso',response);
          // console.log('login sucesso',nivelRota[response.usuario.nivelCargo]);
          // // console.log(store.getters.getNivelCargo);
          const projects = AuthService.getProjects();
          if(projects.length > 1){
            router.push({ name: "projects" });
          }else if(projects.length === 1){
            const idProject = projects[0].id
            localStorage.removeItem('projects');
            localStorage.setItem('id_project', idProject)
            router.push({ name: "listaTickets" });
          }
        })
        .catch(() => {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          submitButton.value?.removeAttribute("disabled");
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Tente novamente!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      fullscreenLoading,
      remember,
      distribuidor,
      linkAndroid,
      linkIOS,
    };
  },
});
